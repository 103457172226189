import * as React from "react"
// import { Link } from "gatsby"
import {css} from "@emotion/react"
import styled from "@emotion/styled"
import {StaticImage} from "gatsby-plugin-image"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

import ColoredWrapper from "../../components/wrappers/colorWrapper"
import ContentWrapper from "../../components/wrappers/contentWrapper"
import SubHeading from "../../components/typography/subHeading";
import Heading from "../../components/typography/heading";
import SpanBold from "../../components/typography/SpanBold";

const HeroImage = styled.div`
  margin: 0 auto;
  width: 100%;
  max-height: 600px;
  overflow: hidden;
`

const AccountsPayablePage = () => {
  return (
    <Layout>
      <Seo
        title="Accounts Payable Services"
        description="Helping small businesses with accounts payable, including paperless records, invoice tracking, and more"
        canonical="https://labellebookkeeping.com/services/accounts-payable/"
      />
      <HeroImage>
        <StaticImage
          src="../../images/hero/hero_accounts_payable_services.jpg"
          alt="quickbooks pro advisor hero image"
          objectPosition="50% 50%"
        />
      </HeroImage>

      <ContentWrapper>
        <Heading>
          Accounts Payable Services
        </Heading>
        <p
          css={css`
            margin-top: var(--marginTop);
          `}
        >
          Keeping up with accounts payable is a surefire way to avoid interest charges and keep your cash flow in check. We can help you do that!
        </p>
        <p><SpanBold>Services include:</SpanBold>
        </p>
        <p>
          <ul>
            <li>Completely paperless invoice management</li>
            <li>Implementation and tracking of invoice payments</li>
            <li>Employee expense reimbursements</li>
            <li>Unique schedule of A/P reports based on client needs</li>
          </ul>
        </p>
        <SubHeading>
          Time To Go Paperless!
        </SubHeading>
        <p>I'll help you go paperless and securely store your invoices and receipts in the cloud!</p>
        <p>Over the last few years, it's become common for businesses to submit or approve receipts by simply taking a photo on their phone and then syncing them up to their records on the cloud. Together, we'll set this up, so you never have to worry about losing hard copies again - it's that simple.</p>
        <SubHeading>Implementation and tracking of invoice payments</SubHeading>
        <p>Maintaining a healthy cash flow while avoiding interest charges where possible is extremely important. So I'll make sure your finances are in check, invoices are paid on time, and I will keep track of payments.</p>
        <SubHeading>Employee expense reimbursements</SubHeading>
        <p>I will help you manage to pay your employee's expense reimbursements so that they're paid on time, and there are no duplicate expense overpayments. This includes tracking each employee's expenses and providing a summary when needed. Furthermore, I like to run on a process where you can approve each employee's reimbursement so you know how your money is being spent.</p>
        <SubHeading>Unique schedule of A/P reports based on client needs</SubHeading>
        <p>I will provide unique reports specifically tailored for your business! These include accounts payable ageing or specific vendor reports for your larger vendor purchases and services.</p>
      </ContentWrapper>


      <ColoredWrapper>
        <ContentWrapper>
          <Heading>FAQs</Heading>
          <SubHeading>What is Accounts Payable?</SubHeading>
          <p>Accounts Payable is the record of outstanding bills for your business. This is used to track the money you owe your vendors and creditors. By tracking vendors and bills, you can better understand your cash flow and where your money goes.</p>

          <SubHeading>What are examples of accounts payable?</SubHeading>
          <p>One example of accounts payable would be a monthly cell phone bill. Another is a trade invoice from a subcontractor or inventory purchase from a vendor.</p>

          <SubHeading>What Bills Should Be Tracked?</SubHeading>
          <p>Ideally, all of them! Understanding where your cash is going is just as important as understanding where your cash flow comes from. By fully recording each bill, it gives you the knowledge on what may be taking up more investment than it should or where you can invest more of your money for better profits.</p>
        </ContentWrapper>
      </ColoredWrapper>
    </Layout>
  )
}

export default AccountsPayablePage
